import React from 'react'
import styled from 'styled-components'
import { mq } from 'core/theme'

export const SidebarLogo = () => (
    <Container>
        <StyledSVG xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1177 370">
            <g>
                <path d="M511.306 94.832c0 102.212-110.308 119.416-110.308 223.652V362h160.908v-50.6H456.658c-.506-2.53-.506-5.06-.506-7.59 0-58.19 110.814-99.682 110.814-211.002 0-56.672-28.336-89.056-82.984-89.056-54.648 0-82.984 32.384-82.984 89.056v34.408h52.624v-37.95c0-25.3 11.132-34.914 28.842-34.914s28.842 8.602 28.842 40.48zM652.725 89.266c0-25.3 11.132-34.914 28.842-34.914s28.842 9.614 28.842 34.914v191.268c0 25.3-11.132 34.914-28.842 34.914s-28.842-9.614-28.842-34.914V89.266zm-55.66 187.726c0 56.672 29.854 89.056 84.502 89.056 54.648 0 84.502-32.384 84.502-89.056V92.808c0-56.672-29.854-89.056-84.502-89.056-54.648 0-84.502 32.384-84.502 89.056v184.184zM912.548 94.832c0 102.212-110.308 119.416-110.308 223.652V362h160.908v-50.6H857.9c-.506-2.53-.506-5.06-.506-7.59 0-58.19 110.814-99.682 110.814-211.002 0-56.672-28.336-89.056-82.984-89.056-54.648 0-82.984 32.384-82.984 89.056v34.408h52.624v-37.95c0-25.3 11.132-34.914 28.842-34.914s28.842 8.602 28.842 40.48zM1113.17 94.832c0 102.212-110.31 119.416-110.31 223.652V362h160.91v-50.6h-105.25c-.5-2.53-.5-5.06-.5-7.59 0-58.19 110.81-99.682 110.81-211.002 0-56.672-28.34-89.056-82.98-89.056-54.65 0-82.99 32.384-82.99 89.056v34.408h52.63v-37.95c0-25.3 11.13-34.914 28.84-34.914s28.84 8.602 28.84 40.48zM22.73 271.041l13.032 7.524L180.944 27.11l-13.032-7.524L22.73 271.04z"></path>
                <path d="M310.11 250.952H19.746V266H310.11v-15.048z"></path>
                <path d="M25.491 259.406l145.237 83.855 7.524-13.032-145.236-83.855-7.524 13.032zM151.658 40.859l145.237 83.855 7.524-13.032-145.236-83.855-7.525 13.032z"></path>
                <path d="M25.532 111.624l7.524 13.032 145.236-83.854-7.524-13.032-145.236 83.854z"></path>
                <path d="M149.035 27.113l145.182 251.455 13.032-7.524L162.067 19.588l-13.032 7.525zM46.307 101.645H31.26v167.709h15.048V101.645z"></path>
                <path d="M298.688 101.645h-15.049v167.709h15.049V101.645z"></path>
                <path d="M161.594 325.507l6.572 11.384 126.316-72.931-6.572-11.384-126.316 72.931z"></path>
                <path d="M318.631 274.25c-8.702 15.139-28.102 20.306-43.241 11.604-15.14-8.703-20.307-28.103-11.604-43.242 8.703-15.139 28.103-20.307 43.242-11.604 15.23 8.793 20.397 28.103 11.603 43.242zM66.07 128.388c-8.703 15.139-28.103 20.307-43.242 11.604C7.69 131.289 2.522 111.889 11.225 96.75c8.702-15.14 28.102-20.306 43.241-11.604 15.14 8.794 20.307 28.103 11.604 43.242zM11.315 274.25c-8.703-15.139-3.535-34.449 11.604-43.242 15.139-8.703 34.448-3.535 43.242 11.604 8.702 15.139 3.535 34.448-11.604 43.242-15.23 8.702-34.54 3.535-43.242-11.604zM263.876 128.388c-8.702-15.139-3.535-34.448 11.604-43.242 15.139-8.703 34.449-3.535 43.242 11.604 8.703 15.139 3.535 34.448-11.604 43.242-15.139 8.703-34.539 3.535-43.242-11.604zM164.973 363c-17.496 0-31.638-14.142-31.638-31.638 0-17.497 14.142-31.638 31.638-31.638 17.496 0 31.638 14.141 31.638 31.638 0 17.405-14.142 31.638-31.638 31.638zM164.973 71.276c-17.496 0-31.638-14.142-31.638-31.638C133.335 22.142 147.477 8 164.973 8c17.496 0 31.638 14.142 31.638 31.638 0 17.496-14.142 31.638-31.638 31.638z"></path>
            </g>
        </StyledSVG>
    </Container>
)

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
`

const StyledSVG = styled.svg`
    width: 45%;
    color: ${({ theme }) => theme.colors.text};
`

export default SidebarLogo
